<div class="container-fluid" >
  <div class="row">
    <div class="p-1" style="width: 250px; ">
      <a class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-decoration-none">
        <svg class="bi pe-none me-2" width="40" height="32">
          <use xlink:href="#bootstrap"></use>
        </svg>
        <span class="fs-4">Simulador 3D</span>
      </a>
      <hr>
      <div style="max-height: calc(100vh - 100px) !important; overflow-y: scroll">
        <form [formGroup]="form">

          <div class="accordion accordion-flush " id="accordionFlushExample">
            
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button p-2" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                  Dimensões
                </button>
              </h2>
              <div id="flush-collapseTwo" class="accordion-collapse">
                <div class="accordion-body" formGroupName="size">
                  <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">Comprimento</label>
                    <div class="input-group mb-3">
                      <input type="text" class="form-control" id="width" formControlName="width">
                      <span class="input-group-text" id="basic-addon2">cm</span>
                    </div>
                  </div>
                  <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">Altura</label>
                    <div class="input-group mb-3">
                      <input type="text" class="form-control" id="heigth" formControlName="heigth">
                      <span class="input-group-text" id="basic-addon2">cm</span>
                    </div>
                  </div>
                  <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">Largura</label>
                    <div class="input-group mb-3">
                      <input type="text" class="form-control" id="length" formControlName="length">
                      <span class="input-group-text" id="basic-addon2">cm</span>
                    </div>
                  </div>

                  <button type="button" (click)="updateSize()" class="btn btn-primary btn-sm">Aplicar</button>
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button p-2" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                  Cor
                </button>
              </h2>
              <div id="flush-collapseThree" class="accordion-collapse">
                <div class="accordion-body">

                  <input type="radio" name="color" class="color" id="c1" value="#0E0E10" formControlName="color" (change)="onColorChanged($event)" />
                  <label class="color" for="c1"><span class="c1"></span></label>

                  <input type="radio" name="color" class="color" id="c2" value="#4B3527" formControlName="color" (change)="onColorChanged($event)" />
                  <label class="color" for="c2"><span class="c2"></span></label>

                  <input type="radio" name="color" class="color" id="c3" value="#C5C7C4" formControlName="color" (change)="onColorChanged($event)" />
                  <label class="color" for="c3"><span class="c3"></span></label>


                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header ">
                <button class="accordion-button p-2" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                  Imagem de fundo
                </button>
              </h2>
              <div id="flush-collapseOne" class="accordion-collapse">
                <div class="accordion-body">
                 
                  <div class="mb-3">
                    <!-- <label for="formFile" class="form-label">Imagem de fundo</label> -->
                    <input class="form-control form-control-sm" type="file" id="formFile" (change)="onFileSelected($event)">
                  </div>

                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button p-2" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne1" aria-expanded="false" aria-controls="flush-collapseOne">
                  Ajustes
                </button>
              </h2>
              <div id="flush-collapseOne1" class="accordion-collapse">
                <div class="accordion-body">

                  <div class="mb-3">
                    <label for="customRange1" class="form-label">Escala</label>
                    <input value="1" type="range" min="0.1" max="3" step="0.1" (change)="onScaleChanged($event)" class="form-range" id="myRange">
                  </div>
                  <div class="mb-3">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="1" checked (change)="toogleTransformControlsVisibility($event)">
                      <label class="form-check-label" for="inlineCheckbox1">Mostrar controlos de ajuste </label>
                    </div>
                  </div>
                  <div class="mb-3">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" checked value="option1" (change)="toogleTransformControlsMode('translate')">
                      <label class="form-check-label" for="inlineRadio1">Posição</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" (change)="toogleTransformControlsMode('rotate')">
                      <label class="form-check-label" for="inlineRadio2">Rotação</label>
                    </div>
                  </div>


                </div>
              </div>
            </div>
            
          </div>
        </form>
        <hr>
        <div class="d-grid gap-2">
          <button type="button" (click)="saveFile()" class="btn btn-primary">Exportar simulação</button>
        </div>

        <p class="mt-5">
          <small>© Copyright 2024 - Digitextus. All Rights Reserved.</small>
        </p>
      </div>
      <!-- <div class="dropdown">
        <a href="#" class="d-flex align-items-center link-body-emphasis text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <img src="https://github.com/mdo.png" alt="" width="32" height="32" class="rounded-circle me-2">
          <strong>mdo</strong>
        </a>
        <ul class="dropdown-menu text-small shadow">
          <li><a class="dropdown-item" href="#">New project...</a></li>
          <li><a class="dropdown-item" href="#">Settings</a></li>
          <li><a class="dropdown-item" href="#">Profile</a></li>
          <li><hr class="dropdown-divider"></li>
          <li><a class="dropdown-item" href="#">Sign out</a></li>
        </ul>
      </div> -->
    </div>

    <main class="col p-2 ">

      <div class="border border-dark rounded-2">
        <canvas #rendererCanvas id="renderCanvas" class="w-100"></canvas>
      </div>

    </main>
  </div>
</div>
import * as THREE from 'three';
import { ElementRef, Injectable, NgZone, OnDestroy } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PergolaService implements OnDestroy {

    group = new THREE.Group();
    color = "#0E0E10"


    public constructor(private ngZone: NgZone) {
    }

    public ngOnDestroy(): void {
    }

    public create(width: number, height: number, length: number): THREE.Group {
        // var height = 3
        // var length = 3.6
        // var width = 3.6

        this.group = new THREE.Group();

        this.group.castShadow = true; //default is false
        this.group.receiveShadow = true;
        this.group.position.y = 0
        //group.scale.set(2,2,2)

        /// Create and position the posts
        const posts = [];
        const postPositions = [
            [-width / 2, height / 2, -length / 2],
            [-width / 2, height / 2, length / 2],
            [width / 2,  height / 2, -length / 2],
            [width / 2,  height / 2, length / 2]
        ];
        postPositions.forEach(pos => {
            const post = this.createPost(height);
            post.position.set(pos[0], pos[1], pos[2]);
            this.group.add(post);
            posts.push(post);
        });


        // Create and position the beams
        //const beams = [];
        /*
        const beamLength = width;
        const beamPositions = [

            [0,         height, -length / 2],
            [0,         height, length / 2],
            [-length / 2, height, 0],
            [length / 2, height, 0]
        ];
        beamPositions.forEach((pos, index) => {
            const beam = this.createBeam(beamLength);
            beam.position.set(pos[0], pos[1], pos[2]);
            if (index >= 2) {
                beam.rotation.y = Math.PI / 2; // Rotate to horizontal
            }
            this.group.add(beam);
            
        });
        */
        const beam1 = this.createBeam(width);
        beam1.position.set(0, height, -length / 2);
        this.group.add(beam1);

        const beam2 = this.createBeam(width);
        beam2.position.set(0, height, length / 2);
        this.group.add(beam2);

        const beam3 = this.createBeam(length);
        beam3.position.set(-width / 2, height, 0);
        beam3.rotation.y = Math.PI / 2;
        this.group.add(beam3);

        const beam4 = this.createBeam(length);
        beam4.position.set(width / 2, height, 0);
        beam4.rotation.y = Math.PI / 2;
        this.group.add(beam4);




        // Create and position the lames

        //console.log(this.lameAngle)

        const lamePositions = [
            [0, height, -width / 2],
        ];
        var pos = lamePositions[0];
        for (let i = 0; i < width; i += 1) {
            const lame = this.createLame(length);

            lame.position.set(pos[0], pos[1], pos[2] + i);
            lame.rotation.x = 0;
            // this.group.add(lame);

            //this.lameArray.push(lame)
        }

/*
        var planeG = new THREE.PlaneGeometry(20, 20);
        var planeM = new THREE.ShadowMaterial();
        // const planeM = new THREE.MeshLambertMaterial({ color: 0x8B4513 });
        // planeM.transparent = true;
        planeM.opacity = 0.5
        var plane = new THREE.Mesh(planeG, planeM);
        plane.rotation.x = -Math.PI / 2;
        // plane.position.y = 0;
        plane.castShadow = true;
        plane.receiveShadow = true;
        this.group.add(plane);
*/


        return this.group;
    }

    updateScale(scale: number) {

        this.group.scale.set(scale, scale, scale)
    }

    changeColor(color: string){
        
        this.group.traverse( function( node ) {

            if ( node instanceof THREE.Mesh ) {
        
                // insert your code here, for example:
                node.material.color = new THREE.Color(color)
        
            }
        
        } );
    }



    private createPost(height: number) {
        var wide = 0.15
        const material = new THREE.MeshLambertMaterial({ color: new THREE.Color(this.color) });

        const geometry = new THREE.BoxGeometry(wide, height, wide); // Narrow and tall box
        const post = new THREE.Mesh(geometry, material);
        post.castShadow = true; //default is false
        post.receiveShadow = false; //default
        return post;
    }

    private createBeam(length) {
        var bHeight = 0.2
        var bWidth = 0.15
        const material = new THREE.MeshLambertMaterial({ color: new THREE.Color(this.color) });
        const geometry = new THREE.BoxGeometry(length, bHeight, bWidth); // Long and narrow box
        const beam = new THREE.Mesh(geometry, material);
        beam.castShadow = true; //default is false
        beam.receiveShadow = false; //default
        return beam;
    }

    private createLame(length) {
        var lHeigth = 0.2
        var lWidth = 0.03
        const material = new THREE.MeshLambertMaterial({ color: new THREE.Color(this.color) });
        const geometry = new THREE.BoxGeometry(length, lHeigth, lWidth); // Long and narrow box
        const beam = new THREE.Mesh(geometry, material);
        beam.castShadow = true; //default is false
        beam.receiveShadow = false; //default
        return beam;
    }



}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { EngineService } from './engine.service';
import { PergolaService } from './pergola.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-engine',
  templateUrl: './engine.component.html',
  styleUrl: './engine.component.scss'
})
export class EngineComponent implements OnInit {

  @ViewChild('rendererCanvas', { static: true })
  public rendererCanvas: ElementRef<HTMLCanvasElement>;

  scale: number = 1
  pergola: THREE.Group
  form: FormGroup

  public constructor(
    private engineService: EngineService,
    private pergolaService: PergolaService
  ) {
    this.form = new FormGroup({
      firstName: new FormControl(''),
      color: new FormControl('#0E0E10'),
      size: new FormGroup({
        width: new FormControl('360'),
        heigth: new FormControl('300'),
        length: new FormControl('360'),
      }),
    });
  }

  public ngOnInit(): void {

    this.engineService.createScene(this.rendererCanvas);
    this.engineService.animate();

    this.demo();
  }

  onFileSelected(event: any) {

    const file: File = event.target.files[0];

    if (file) {

      var u = window.URL.createObjectURL(file);
      this.engineService.loadBackgroundImagem(u);

      this.updateSize()
    }
  }

  onColorChanged(event: any) {
    var color = event.currentTarget.value;
    console.log(color)
    this.pergolaService.changeColor(color)
  }

  onScaleChanged(event: any) {
    console.log(event.currentTarget.value)
    var scale = parseFloat(event.currentTarget.value);
    this.pergolaService.updateScale(scale);
  }

  toogleTransformControlsMode(mode: 'translate' | 'rotate') {
    this.engineService.toogleTransformControlsMode(mode);
  }

  toogleTransformControlsVisibility(event: any) {
    console.log(event.currentTarget.checked)
    this.engineService.toogleTransformControlsVisibility(event.currentTarget.checked);
  }

  demo() {

    var width = this.form.value.size.width / 100;
    var height = this.form.value.size.heigth / 100;
    var length = this.form.value.size.length / 100;

    this.pergola = this.pergolaService.create(width, height, length);
    this.engineService.addGroupDemo(this.pergola);

    
  }

  updateSize() {

    var width = this.form.value.size.width / 100;
    var height = this.form.value.size.heigth / 100;
    var length = this.form.value.size.length / 100;

    this.pergola = this.pergolaService.create(width, height, length);
    this.engineService.addGroup(this.pergola);

    
  }

  saveFile() {
    this.engineService.saveAsImage();
  }

}

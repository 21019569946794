import * as THREE from 'three';
import { ElementRef, Injectable, NgZone, OnDestroy } from '@angular/core';
//import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { TransformControls } from '../../assets/TransformControls';
//import * as Polyglot from '../../../node_modules/node-polyglot/build/polyglot.min.js';

@Injectable({ providedIn: 'root' })
export class EngineService implements OnDestroy {

  private canvas: HTMLCanvasElement;
  private renderer: THREE.WebGLRenderer;
  private camera: THREE.PerspectiveCamera;
  private cameraOrtho: THREE.OrthographicCamera;
  private cameraHelper: THREE.CameraHelper;
  private scene: THREE.Scene;
  private light: THREE.AmbientLight;
  private controls: OrbitControls;
  private transformControl: TransformControls;
  private directionalLight: THREE.DirectionalLight;
  private group: THREE.Group

  private frameId: number = null;

  private cameraAspect = 1.41

  public constructor(private ngZone: NgZone) {
  }

  public ngOnDestroy(): void {
    if (this.frameId != null) {
      cancelAnimationFrame(this.frameId);
    }
    if (this.renderer != null) {
      this.renderer.dispose();
      this.renderer = null;
      this.canvas = null;
    }
  }

  public createScene(canvas: ElementRef<HTMLCanvasElement>): void {
    // The first step is to get the reference of the canvas element from our HTML document
    this.canvas = canvas.nativeElement;

    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      alpha: true,    // transparent background
      antialias: true, // smooth edges
      preserveDrawingBuffer: true
    });

    this.renderer.setClearColor(0x000000, 0);
    this.renderer.setPixelRatio(devicePixelRatio);
    this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientWidth / this.cameraAspect);
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap; // default THREE.PCFShadowMap

    // create the scene
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0xdddddd);
    //bg image
    const textureLoader = new THREE.TextureLoader();
    this.scene.background = textureLoader.load('./assets/house6.jpg');
    

    this.camera = new THREE.PerspectiveCamera(65, this.cameraAspect, 0.1, 100);
    this.camera.position.set(0, 2, 10);    
    this.scene.add(this.camera);

//    const frustumSize = 2;
  //  this.cameraOrtho = new THREE.OrthographicCamera( - frustumSize * this.cameraAspect, frustumSize * this.cameraAspect, frustumSize, - frustumSize, 0.1, 100 );
   // this.scene.add(this.cameraOrtho);

    // soft white light
    this.light = new THREE.AmbientLight(0xffffff, 0.5);
    this.scene.add(this.light);

    this.directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    this.directionalLight.position.set(0, 20, 0);
    this.directionalLight.castShadow = true; // default false
    this.scene.add(this.light, this.directionalLight);

    const helper = new THREE.DirectionalLightHelper(this.directionalLight);
    //this.scene.add(helper);

    this.cameraHelper = new THREE.CameraHelper( this.camera );
    //this.scene.add( this.cameraHelper );

    const gridHelper = new THREE.GridHelper(50, 50);
    //gridHelper.position.y = -5
    //this.scene.add(gridHelper);
    this.configControls();
  }

  configControls() {
    this.controls = new OrbitControls(this.camera, this.canvas);
    //   this.controls.autoRotate = false;
    //   this.controls.enableZoom = false;
    //  this.controls.enablePan = false;
    // this.controls.enabled = false;
    //this.controls.enabled = false;
     
    
    this.controls.update();

     this.transformControl = new TransformControls(this.camera, this.canvas);
     this.transformControl.mode = 'translate'
     this.scene.add(this.transformControl);
  }

  public loadBackgroundImagem(image: any){
    const textureLoader = new THREE.TextureLoader();
    this.scene.background = textureLoader.load(image);
  }

  public addGroup(group: THREE.Group) {

    console.log('addGroup')

    this.group = group;
    this.group.name = 'p1';

    const obj = this.scene.getObjectByName('p1');
    if (obj){
      this.scene.remove(obj);
    }

    this.scene.add(this.group);

    this.transformControl.attach(this.group);
  }

  public addGroupDemo(group: THREE.Group) {

    console.log('addGroup')

    this.group = group;
    this.group.name = 'p1';

    const obj = this.scene.getObjectByName('p1');
    if (obj){
      this.scene.remove(obj);
    }

    this.scene.add(this.group);

    this.transformControl.attach(this.group);

     this.group.position.set( 6.939836421463411,-4.717031621905558, -8.600251678842806)
     this.group.scale.set(2.3,2.3,2.3)
     this.group.rotation.set(-0.08987972531018142, -0.6398030591869217, 0)
    
     this.directionalLight.position.set(this.group.position.x, this.group.position.y + 10, this.group.position.z);
     this.directionalLight.target = this.group;
  }

  public toogleTransformControlsMode(mode: string){
    this.transformControl.setMode(mode);
   
  }

  

  public toogleTransformControlsVisibility(mode: boolean){
    if (mode == true)
      this.transformControl.attach(this.group);
    else
      this.transformControl.detach();
   
  }

  public animate(): void {
    // We have to run this outside angular zones,
    // because it could trigger heavy changeDetection cycles.
    this.ngZone.runOutsideAngular(() => {
      if (document.readyState !== 'loading') {
        this.render();
      } else {
        window.addEventListener('DOMContentLoaded', () => {
          this.render();
        });
      }

      window.addEventListener('resize', () => {
        this.resize();
      });

      this.controls.addEventListener("change", (e) => {
        //console.log(e.target.object.rotation);
        //console.log(e.target.object.camera);
      });

      this.transformControl.addEventListener('objectChange', this.transformControlObjectChanged.bind(this));
      this.transformControl.addEventListener('dragging-changed', this.transformControlDraggingChanged.bind(this));

    });
  }

  private transformControlObjectChanged(e: any) {
    var pos = e.target.object.position;
    var rot = e.target.object.rotation;
   this.directionalLight.position.set(pos.x, pos.y + 10, pos.z);
    this.directionalLight.target = e.target.object;

    console.log('pos: ')
    console.log(pos)
    console.log('rot: ')
    console.log(rot)
  }

  private transformControlDraggingChanged(event: any) {
    this.controls.enabled = !event['value'];
  }

  public render(): void {
    this.frameId = requestAnimationFrame(() => {
      this.render();
    });

    //this.cube.rotation.x += 0.01;
    //this.cube.rotation.y += 0.01;
    this.cameraHelper.update();
    this.cameraHelper.visible = true;
    this.renderer.render(this.scene, this.camera);
  }

  public resize(): void {
    const width = this.canvas.clientWidth;
    const height = this.canvas.clientWidth / this.cameraAspect;

    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
    this.cameraHelper.update();

    this.renderer.setSize(width, height);

    console.log('clientWidth', width);
    console.log('clientHeight', height);
  }

  public saveAsImage() {
    var imgData, imgNode;

    try {
        var strMime = "image/jpeg";
        var strDownloadMime = "image/octet-stream";

        imgData = this.renderer.domElement.toDataURL(strMime);

        this.saveFile(imgData.replace(strMime, strDownloadMime), "test.jpg");

    } catch (e) {
        console.log(e);
        return;
    }

}

private saveFile = function (strData, filename) {
  var link = document.createElement('a');
  if (typeof link.download === 'string') {
      document.body.appendChild(link); //Firefox requires the link to be in the body
      link.download = filename;
      link.href = strData;
      link.click();
      document.body.removeChild(link); //remove the link when done
  } else {
     // location.replace(uri);
  }
}


}
